/**
 * @author  XuHongli
 * @date  2022/12/2 14:03
 * @version 1.0
 * @description 选品库 组内数据 表格列 配置项
 */

export const GroupDetailTableColumns = [
  {
    title: '商品信息',
    dataIndex: 'id',
    scopedSlots: { customRender: 'description' },
    width: '320px'
  },
  {
    title: '商品状态',
    dataIndex: 'status',
    width: '100px',
    align: 'center',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '价格',
    dataIndex: 'tradePrice',
    align: 'center'
  },
  {
    title: '市场价',
    scopedSlots: { customRender: 'suggestedRetailPrice' },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    width: '100px',
    scopedSlots: { customRender: 'action' },
    align: 'center'
  }
]
