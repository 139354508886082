<template>
  <div class="GroupDetailTable bg-white p-10">
    <div class="GroupData">
      <h6>{{ GroupData.name }}</h6>
      <p class="font-bold"> 组ID: {{ queryParam.groupId }}</p>
      <p class="font-bold"> 商品数量: <span class="text-danger"> {{ GroupData.total }} </span></p>
    </div>
    <div class="table-page-search-wrapper mt-10">
      <a-form layout="inline">
        <a-row :gutter="10">
          <a-col :md="6" :sm="24">
            <a-form-item label="商品名称">
              <a-input v-model="queryParam.name" placeholder="请输入商品名称" size="small" />
            </a-form-item>
          </a-col>
          <a-col :md="!advanced && 8 || 8" :sm="24">
            <span
              :style="advanced && { float: 'right', overflow: 'hidden' } || {} "
              class="table-page-search-submitButtons">
              <a-button size="small" type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button
                size="small"
                style="margin-left: 8px"
                @click="() => {this.queryParam = { groupId: this.$route.params.groupId }}">重置</a-button>
              <a-dropdown >
                <a-menu slot="overlay" @click="DropdownOnClick">
                  <a-menu-item key="1">
                    <a-icon type="diff" />
                    批量移动
                  </a-menu-item>
                  <a-menu-item key="2">
                    <a-icon type="delete" />
                    批量删除
                  </a-menu-item>
                  <a-menu-item key="3">
                    <a-icon type="api" />
                    一键同步
                  </a-menu-item>
                </a-menu>
                <a-button size="small" style="margin-left: 8px">
                  批量操作
                  <a-icon type="down" />
                </a-button>
              </a-dropdown>
              <template v-if="prodctCodes.length">
                <a-select v-model="selectCode" placeholder="请选择产品" size="small" style="width: 120px;margin-left: 15px;">
                  <a-select-option v-for="(item, index) in prodctCodes" :key="index" :value="item.value">
                    {{ item.label }}
                 </a-select-option>
                </a-select>
               </template>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      :alert="true"
      :columns="GroupDetailTableColumns"
      :data="loadData"
      :pagination="{
        pageSizeOptions: ['10', '20', '30', '40', '100', '500']
      }"
      :rowSelection="rowSelection"
      :scroll="{x:900, y:600}"
      bordered
      rowKey="id"
      size="middle"
    >
      <div slot="description" slot-scope="text, record">
        <div
          class="flex-row flex-col-center"
          style="cursor: pointer"
          @click="openCommodityPage(record.id)"
        >
          <img :src="record.imgUrl" height="80" style="padding: 5px" width="80">
          <div class=" flex-column flex-row-between" style="padding: 5px">
            <Ellipsis :length="48" tooltip>{{ record.name }}</Ellipsis>
            <br>
            <span class="text-sm text-primary" @click.stop="onMore(record.id)">更多SKU</span>
          </div>
        </div>
      </div>
      <div slot="status">
        <p class="text-md">有效</p>
      </div>
      <div slot="suggestedRetailPrice" slot-scope="text, record">
<!--        {{ (Number(record.tradePrice) * 2).toFixed(2) }}-->
        {{ record.marketPrice ? record.marketPrice.toFixed(2) : '' }}
      </div>
      <div slot="action" slot-scope="text, record">

        <a-popconfirm cancel-text="否" ok-text="是" title="是否确认删除？" @confirm="handleDelete(record.id)">
          <a-button size="small" type="link">删除</a-button>
        </a-popconfirm>
      </div>
    </s-table>
    <MoveGroupGoodsModal
      v-if="showMoveGroupGoodsModal"
      v-model="showMoveGroupGoodsModal"
      :ids="selectedRowKeys"
      @ok="$refs.table.refresh(true)">
    </MoveGroupGoodsModal>
    <DeleteGoodsFromGroupModal
      v-if="showDeleteGoodsModal"
      v-model="showDeleteGoodsModal"
      :ids="selectedRowKeys"
      @ok="$refs.table.refresh(true)">
      >
    </DeleteGoodsFromGroupModal>
    <!--    对话框：更多Sku信息-->
    <el-dialog :before-close="() => {columns = []; dialogTableVisible = false}" :visible.sync="dialogTableVisible" title="更多商品Sku信息" width="70%">
      <a-table
        :columns="columns"
        :dataSource="goodSkuData"
        :pagination="goodSkuData.length > 50"
        :rowKey="(record) => record.id"
        bordered
        size="middle"
      >
      </a-table>
    </el-dialog>
  </div>
</template>

<script>

import { GroupDetailTableColumns } from '@/views/User/ShopLibrary/GroupDetailTable/GroupDetailTableColumns'
import STable from '@/components/Table'
import Ellipsis from '@/components/Ellipsis'
import { deleteGoodsFromGroup, getGroupData, queryGroupDetailList, syncGoods, getMoreSkuData } from '@/api/SelectGoods'
import { getProductSpec } from '@/api/Product'
import { mapGetters } from 'vuex'
import MoveGroupGoodsModal from '@/components/Modal/MoveGroupGoodsModal'
import DeleteGoodsFromGroupModal from '@/components/Modal/DeleteGoodsFromGroupModal'
import { Modal } from 'ant-design-vue'
/**
 * @author  XuHongli
 * @date  2022/12/2 13:41
 * @version 1.0
 * @description 商品库组内详情数据
 */
export default {
  name: 'GroupDetailTable',
  components: {
    DeleteGoodsFromGroupModal,
    MoveGroupGoodsModal,
    STable,
    Ellipsis
  },
  data() {
    return {
      // 表格列配置
      columns: [],
      // 更多Sku信息展示
      dialogTableVisible: false,
      // 更多Sku数据
      goodSkuData: [],
      // Sku规格名
      goodSpecData: [],
      // 组信息
      GroupData: {},
      // 移动分组弹窗
      showMoveGroupGoodsModal: false,
      // 批量删除弹窗
      showDeleteGoodsModal: false,
      // 列配置项
      GroupDetailTableColumns,
      // 查询参数
      queryParam: {
        groupId: this.$route.params.groupId
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const that = this
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
          return queryGroupDetailList(requestParameters)
            .then(res => {
              // 组内商品数
              this.GroupData.total = res.data.total
              console.log('res订单列表:', res)
              this.selectedRowKeys = []
              this.selectedRows = []
              return res.data
            })
        }
      },
      selectedRowKeys: [],
      selectedRows: [],
      // V5商城-编码
      prodctCodes: [],
      selectCode: '',
    }
  },
  created() {
    getGroupData(this.queryParam.groupId).then((res) => {
      this.GroupData = Object.assign(this.GroupData, res.data)
      this.$forceUpdate()
    })
    this.initV5Shop()
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods: {
    initV5Shop () {
      if (!localStorage.getItem('v5_Shop')) return
      let v5_Shop = localStorage.getItem('v5_Shop') ? JSON.parse(localStorage.getItem('v5_Shop')) : []
      for (let item of v5_Shop) {
        this.prodctCodes.push({
          'label': item == '88000003' ? '商城' : '盲盒商城',
          'value': item
        })
      }
      this.selectCode = this.prodctCodes[0].value
    },
    // 打开更多sku显示
    onMore(id) {
      getMoreSkuData(id).then((re) => {
        let { data } = re
        this.goodSkuData = data
        // 规格表数据
        getProductSpec(id).then((res) => {
          this.goodSpecData = res.data
          this.dialogTableVisible = true
          // 根据规格列表添加 表格列配置项
          this.goodSpecData.map((item,index) => {
            // 在规格里面添加index, 因为要比较多item的sku需要固定在左侧, index是用来取tableData数据用
            item.index = index
            return item
          })
            .sort((a,b) => a.value.length - b.value.length )
          let skuArray = []
          this.goodSkuData.forEach(item => {
            skuArray = item.specs
          })
          this.goodSpecData.forEach((item,index) => {
            let indexs = skuArray.findIndex(val => val.productSpecName == item.name)
            this.columns.push({
              title: item.name,
              align: 'center',
              width: 85,
              dataIndex: `specs[${indexs}].productSpecValueName`,
            })
          })
        })
      })
    },
    // 打开新窗口商品详情页
    openCommodityPage(id) {
      let PAGE_URL = window.origin + '/commodity/' + id
      window.open(PAGE_URL, '_blank')
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    DropdownOnClick({ key }) {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error('还未选择商品')
        return
      }
      console.log(key)
      // 批量移动
      if (key === '1') {
        this.showMoveGroupGoodsModal = true
      }
      // 批量删除
      if (key === '2') {
        this.showDeleteGoodsModal = true
      }
      // 一键入库
      if (key === '3') {
        Modal.confirm({
          centered: true,
          content: (<div>
            <h6>一键同步</h6>
            <p style={'font-size:16px;letter-space:2px'} class="mt-10">
              您确定将
              <span class="text-danger">{this.selectedRowKeys.length}</span>
              件商品同步
            </p>
          </div>),
          icon: () => (<a-icon style="color:#999" type="api" />),
          onOk: () => {
            return syncGoods(this.selectedRowKeys, this.selectCode).then((res) => {
              this.$message.success(res.msg)
            })
          },
        })
      }
    },
    handleDelete(id) {
      deleteGoodsFromGroup([id]).then((res) => {
        this.$message.success(res.msg)
        this.$refs.table.refresh(true)
      })
    }
  }
}
</script>

<style scoped>

</style>
