<template>
  <a-modal
    :visible="visible"
    cancelText="取消"
    centered
    okText="确认"
    width="618px"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <h6>将已选取的 <span class="text-danger">{{ ids.length }}</span>  件商品移动至 其他分组</h6>
    <a-spin :spinning="loading" tip="Loading...">
      <div class="flex-row flex-col-center">
        <template v-if="!showAddGroup">
          <a-button size="small" type="info" @click="showAddGroup = true">新建普通分组</a-button>
        </template>
        <template v-else>
          <a-input v-model.trim="addGroupName" class="mr-5" placeholder="请输入名称" size="small" style="width: 200px;"></a-input>
          <a-button class="mr-5" size="small" type="primary" @click="addGroup">确定</a-button>
          <a-button size="small" type="info">取消</a-button>
        </template>
      </div>
      <div class="GroupList mt-10">
        <a-card
          v-for="(item, index) in GroupList"
          :key="index"
          :class="['groupCard','mr-10','mb-10', { active : selectGroupsIndex === index }]"
          style="width: 255px"
          @click="selectGroupsIndex = index">
          <p class="groupCard-title">{{ item.name }}</p>
          <p class="groupCard-count">{{ `商品数量: ${item.count}` }}</p>
        </a-card>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'
import { addGroup, getGroupList, moveGoodsToGroup } from '@/api/SelectGoods'

/**
 * @author  XuHongli
 * @date  2022/12/2 14:43
 * @version 1.0
 * @description 将原本商品库内的商品 移动至 别的商品库分组弹窗
 */
export default {
  name: 'MoveGroupGoodsModal',
  mixins: [ ShowModalMixin ],
  props: {
    ids: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      //  分组列表
      GroupList: [],
      showAddGroup: false,
      // 添加分组的名字
      addGroupName: '',
      // 选中的分组
      selectGroupsIndex: '',
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      getGroupList().then((res) => {
        this.GroupList = res.data
        this.loading = false
      })
    },
    // 添加组
    addGroup() {
      // TODO 添加校验逻辑
      this.loading = true
      addGroup(this.addGroupName).then((res) => {
        this.showAddGroup = false
        this.init()
      })
    },
    // 点击确定
    handleOk() {
      if (this.selectGroupsIndex === '') {
        this.$message.error('您还没有选择分组')
      } else {
        this.loading = true
        let queryData = {
          groupId: this.GroupList[this.selectGroupsIndex].id,
          productIds: this.ids
        }
        moveGoodsToGroup(queryData).then((res) => {
          this.$message.success(res.msg)
          this.$emit('ok', true)
          this.closeModal()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.groupCard{
  .ant-card-body{
    padding: 10px;
  }
  &:hover:not(.active){
    background-color: #ededf0;
    .groupCard-title{
      color: $color-orange;
    }
  }
}
.groupCard.active{
  background-color: $color-orange;
  color: white;
}
.GroupList{
  background-color: #fafafa;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  max-height: 420px;
  overflow-y: scroll;
}
</style>
